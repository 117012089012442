import React from "react";
import DefaultLayout from "../layouts/default";
import FluidImage from "../components/FluidImage.js";
import Breadcrumbs from "../components/Custom/Navigation/Breadcrumbs";
import { Link } from "gatsby";

export default ({ location }) => {
	// const dispatch = useDispatch();

	// React.useEffect(() => {

	// });

	return (
		<DefaultLayout location={location}>
			
      	<Breadcrumbs location={location} />

			{/* on this page */}
			<div className="container is-fullwidth">
				<section className="section section-mini">
					<p className="is-normal">
						On this page:{" "}
						<a href="#1" className="">
							Membership
						</a>
						,{" "}
						<a href="#2" className="">
							Become a member
						</a>
						,{" "}
						<a href="#3" className="">
							Active members
						</a>
					</p>
				</section>
			</div>

			{/* membership */}
			<a id="1" className="scroll-anchor"></a>
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>Better together – we are members.</h1>
              				<p>
								&mdash; Members are the people who approach us with ideas and
								build teams to make these ideas happen. Our members are the core
								group and greatest asset of Network Ventures.
							</p>
						</div>
					</div>

					<div className="columns is-tablet mt-25">
						<div className="column">
							<p className="is-normal">
                That’s why we decided to make Network Ventures a cooperative. We want people to get involved not only in projects, but also in the development of the bigger picture. As a member you are involved in all big decisions we have to make as a cooperative.
							</p>
						</div>
						<div className="column">
							<p className="is-normal">
                Questions like: What should happen to the profit we make? Rent some office space? Invest in a new venture? Buy equipment like a 3D printer for making prototypes. Buy a company helicopter? Joking aside, members have a vote in all big decisions of the cooperative.
							</p>
						</div>
					</div>

					<div className="columns is-tablet is-centered">
						<div className="column is-8 mt-50">
						<nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase">
							<ul>
							<li>
								<Link to={"/codeofconduct"}>
								Code of conduct
								<span className="icon icon-arrow-right"></span>
								</Link>
							</li>
							</ul>
						</nav>
						</div>
					</div>

				</section>
			</div>

			{/* become a member */}
			<a id="2" className="scroll-anchor"></a>
			<div className="hero hero-cta is-primary mt-50">
				<div className="hero-body">
					<div className="container is-layout">
						<section className="section">
							<div className="columns is-tablet is-centered">
								<div className="column is-8">
									<h1>Cool, I maybe want to join Network Ventures.</h1>
									<p className="mb-50">
										That's great news. Let's get together and sort everything out.
									</p>
									<a
										href="mailto:hi@ntwk.ventures?subject=Member of Network Ventures"
										className="button is-primary is-inverted is-letter-spacing is-uppercase mb-50"
									>
										Contact us
									</a>
									<p>Is this the beginning of a beautiful friendship?</p>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>

			{/* active members */}
			<a id="3" className="scroll-anchor"></a>
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>The active members who help fly rockets.</h1>
						</div>
					</div>

					<div className="table-container">
						<table className="table is-hoverable is-fullwidth">
							<thead>
								<tr>
									<th>Name</th>
									<th>Membership</th>
									<th>Expertise</th>
									<th>Company</th>
									<th>Contact</th>
								</tr>
							</thead>
							<tbody>
								{/* <tr>
									<td className="title">Michael Baumgartner</td>
									<td>Founder, Board</td>
									<td>Business Development</td>
                  					<td>Treetop Baumgartner Karbon OG</td>
									<td>
										<a href="mailto:michael.baumgartner@ntwk.ventures">Email</a>
									</td>
								</tr> */}
								<tr>
									<td className="title">Thomas Wanner</td>
									<td>Founder, Board</td>
									<td>Software Development</td>
                  					<td>Transporter Werbeagentur OG</td>
									<td>
										<a href="mailto:thomas.wanner@ntwk.ventures">Email</a>
									</td>
								</tr>
								{/* <tr>
									<td className="title">Robert Witting</td>
									<td>Board</td>
									<td>Financial Development</td>
                  					<td></td>
									<td>
										<a href="mailto:robert.witting@ntwk.ventures">Email</a>
									</td>
								</tr> */}
								<tr>
									<td className="title">Rainer Fabrizi</td>
									<td>Founder, Core</td>
									<td>Design and Web</td>
                  					<td>Transporter Werbeagentur OG</td>
									<td>
										<a href="mailto:rainer.fabrizi@ntwk.ventures">Email</a>
									</td>
								</tr>
								<tr>
									<td className="title">Stefan Prindl</td>
									<td>Board</td>
									<td>Business Development</td>
                  					<td>Ski Austria</td>
									<td>
										<a href="mailto:christoph.thaler@ntkw.ventures">Email</a>
									</td>
								</tr>
								{/* <tr>
									<td className="title">Thomas Falkner</td>
									<td>Member</td>
									<td>Marketing</td>
                  					<td></td>
									<td>
										<a href="mailto:thomas@falkner.rocks">Email</a>
									</td>
								</tr> */}
							</tbody>
						</table>
					</div>

					<p className="is-normal">... who's missing from this list? <a href="#2">You?</a></p>

				</section>
			</div>
		
    	</DefaultLayout>
	);
};
